import { storeToRefs } from 'pinia';

import { useSumStore } from './sum.store';
import { usePromoStore } from './promo.store';

import type { IBonusItem } from '~/features/payment/types/bonuses/client.types';
import type { TPossibleNull } from '~/types/Shared.types';

export const useBonusesStore = defineStore('payment/bonuses', () => {
  const {
    $api: { payment: PaymentService },
  } = useNuxtApp();

  /* -- Stores -- */
  /* Стор суммы */
  const sumStore = useSumStore();
  const { sumWithRate } = storeToRefs(sumStore);
  /* Стор промокодов */
  const promoStore = usePromoStore();
  const { commonPromo } = storeToRefs(promoStore);

  /* -- Const -- */
  const bonuses = ref<IBonusItem[]>([]);

  const isLoaded = ref(false);

  /* -- Getters -- */
  const skinsAvailableBonuses = computed(() => bonuses.value.filter((bonus) => bonus.percent > commonPromo.value));
  const activeAndNextBonuses = computed(() => {
    const result: Record<string, TPossibleNull<IBonusItem>> = {
      active: null,
      next: null,
    };

    for (const bonus of bonuses.value) {
      if (bonus.percent <= commonPromo.value) continue;

      if (bonus.from > sumWithRate.value) {
        result.next = bonus;
        break;
      }

      result.active = bonus;
    }

    return result;
  });
  const shownBonusCard = computed(() => {
    const result = {
      isCompany: false,
      percent: 0,
    };

    const activeBonus = activeAndNextBonuses.value.active;

    if (activeBonus) {
      const isActiveBonusLarger = activeBonus.percent > commonPromo.value;
      result.isCompany = isActiveBonusLarger;
      result.percent = Math.max(activeBonus.percent, commonPromo.value);
      return result;
    }

    result.percent = commonPromo.value;
    return result;
  });

  /* -- Methods -- */
  const getBonuses = async () => {
    try {
      bonuses.value = await PaymentService.getBonuses();
    } catch {
    } finally {
      isLoaded.value = true;
    }
  };

  return {
    activeAndNextBonuses,
    bonuses,
    isLoaded,
    getBonuses,
    shownBonusCard,
    skinsAvailableBonuses,
  };
});
